<div class="container">
  <h1 class="text-center pt-4">Reprint Request</h1>

  <form [formGroup]="newRequestForm" (ngSubmit)="onSubmit()">
    <div class="flex-container">
      <div class="flex-column">
        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Your Name</mat-label>
            <input formControlName="name" matInput type="text" />
          </mat-form-field>
        </div>


        <div class="flex-item">

          <mat-form-field appearance="outline" class="">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="Ex. pat@example.com" />
          </mat-form-field>
        </div>


        <div class="flex-item">

          <mat-form-field appearance="outline">
            <mat-label>Ministry You Represent</mat-label>
            <mat-select formControlName="ministry">
              <mat-option *ngFor="let ministry of ministries" [value]="ministry.ministry">
                {{ ministry.ministry }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>


        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Event / Project Name</mat-label>
            <input formControlName="projectName" matInput type="text" />
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Brief Project Description</mat-label>
            <textarea formControlName="desc" matInput type="text" rows="4"></textarea>
          </mat-form-field>
        </div>

        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Quantity Needed</mat-label>
            <input formControlName="qty" matInput type="number" />
          </mat-form-field>
        </div>


        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>All Materials Needed In-Hand</mat-label>
            <input formControlName="materialsInHandDate" [min]="minDate" matInput #date [matDatepicker]="picker2" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
    </div>
    <div class="submit-container pb-5">
      <div class="row pb-5 pt-5" [ngClass]="setClasses()">
        <div class="col-1 p-0 m-0 text-right">
          <mat-checkbox (click)="removeClass()" color="primary" formControlName="ackCheck" class="acknowledgement">
          </mat-checkbox>
        </div>
        <div class="col-10">
          <p>I acknowledge that a reprint request <b>does not</b> include file
            modifications
            or revisions. If I require <b>any</b> changes to the original file, I will submit a regular
            Communications
            Request
            instead.</p>
        </div>
      </div>
      <button mat-raised-button color="primary" type="submit">Submit Request</button>
    </div>
  </form>
</div>
