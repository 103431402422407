import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardButtons } from 'src/app/models/DashboardButtons';

@Component({
  selector: 'app-dashboard-button',
  templateUrl: './dashboard-button.component.html',
  styleUrls: ['./dashboard-button.component.css'],
})
export class DashboardButtonComponent implements OnInit {
  @Output() openDiag = new EventEmitter<string>();
  @Input() button: DashboardButtons;
  @Input() isCommunications: boolean;
  constructor() {}

  openDialog() {
    this.openDiag.emit('open-dialog');
  }

  ngOnInit(): void {}
}
