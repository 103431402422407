import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONTEXT_NAME } from '@angular/compiler/src/render3/view/util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token;
  apiUrl = 'https://api.thechapel.green/auth/microsoft/callback';
  login(token) {
    return this.http.get(`${this.apiUrl}?access_token=${token}`);
  }

  constructor(private http: HttpClient) {}
}
