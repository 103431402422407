import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { DashboardService } from '../../../services/dashboard.service';
import { SignageSlides } from '../../../models/SignageSlides';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signage-display',
  templateUrl: './signage-display.component.html',
  styleUrls: ['./signage-display.component.css'],
})
export class SignageDisplayComponent implements OnInit {
  signageSlides: SignageSlides[];
  slideCount: number;

  constructor(
    public dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let screenName = this.route.snapshot.paramMap.get('screen');
    this.dashboardService.getSlides(screenName).subscribe((signageSlides) => {
      this.signageSlides = signageSlides.sort(
        (a: any, b: any) => a.order - b.order
      );
      this.slideCount = signageSlides.length;
      setInterval(() => {
        this.dashboardService
          .checkForNewSlides(screenName)
          .subscribe((refresh) => {
            const update = refresh[0].screen.Updated;
            if (update) {
              const body = { Updated: false };
              const x = this.dashboardService
                .changeScreenUpdate(refresh[0].screen.id, body)
                .subscribe(() => {
                  this.ngOnInit();
                });
            }
          });
      }, 1000 * 60 * 10);
    });
  }
}
