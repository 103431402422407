import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signage-screen',
  templateUrl: './signage-screen.component.html',
  styleUrls: ['./signage-screen.component.css']
})
export class SignageScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
