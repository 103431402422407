<app-header [isAuthenticated]="isAuthenticated"></app-header>
<router-outlet></router-outlet>

<div class="container pb-5">
  <h3 class="pt-5" *ngIf="isAuthenticated">Quick Links</h3>
  <div class="row" *ngIf="isAuthenticated">

    <ng-container *ngFor="let button of dashboardButtons">
      <app-dashboard-button *ngIf="button.urlType === 'quickLink'" class="col-lg-2 text-center" [button]="button">

      </app-dashboard-button>
    </ng-container>
  </div>

  <h3 class="pt-5" *ngIf="isAuthenticated">Requests</h3>
  <div class="row" *ngIf="isAuthenticated">

    <ng-container *ngFor="let button of dashboardButtons">
      <app-dashboard-button *ngIf="button.urlType === 'requests'" class="col-lg-2 text-center" [button]="button"
        (openDiag)="openDialog()">

      </app-dashboard-button>
    </ng-container>
  </div>

  <h3 class="pt-5" *ngIf="isCommunications">Admin</h3>
  <div *ngIf="isCommunications" class="row">

    <ng-container *ngFor="let button of dashboardButtons">
      <app-dashboard-button *ngIf="button.urlType === 'communications'" class="col-lg-2 text-center" [button]="button">

      </app-dashboard-button>
    </ng-container>
  </div>


</div>
