export function communicationsEmail(
  ministry,
  desc,
  date,
  eventTime,
  location,
  rank,
  demo,
  need,
  solution,
  steps
) {
  var message = `
      <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width"/>
      
      
          <style type="text/css">
          * { margin: 0; padding: 0; font-size: 100%; font-family: 'calibri', sans-serif; line-height: 1.65; }
      
      img { max-width: 100%; margin: 0 auto; display: block; }
      
      body, .body-wrap { width: 100% !important; height: 100%; background: #f8f8f8; }
      
      a { color: #71bc37; text-decoration: none; }
      
      a:hover { text-decoration: underline; }
      
      .text-center { text-align: center; }
      
      .text-right { text-align: right; }
      
      .text-left { text-align: left; }
      
      .button { display: inline-block; color: white; background: #71bc37; border: solid #71bc37; border-width: 10px 20px 8px; font-weight: bold; border-radius: 4px; }
      
      .button:hover { text-decoration: none; }
      
      h1, h2, h3, h4, h5, h6 { margin-bottom: 20px; line-height: 1.25; }
      
      h1 { font-size: 32px; }
      
      h2 { font-size: 28px; }
      
      h3 { font-size: 24px; }
      
      h4 { font-size: 20px; }
      
      h5 { font-size: 16px; }
      
      p, ul, ol { font-size: 16px; font-weight: normal; margin-bottom: 20px; }
      
      .container { display: block !important; clear: both !important; margin: 0 auto !important; max-width: 580px !important; }
      
      .container table { width: 100% !important; border-collapse: collapse; }
      
      .container .masthead { padding: 80px 0; background: #92c841; color: white; }
      
      .container .masthead h1 { margin: 0 auto !important; max-width: 90%; text-transform: uppercase; }
      
      .container .content { background: white; padding: 30px 35px; }
      
      .container .content.footer { background: none; }
      
      .container .content.footer p { margin-bottom: 0; color: #888; text-align: center; font-size: 14px; }
      
      .container .content.footer a { color: #888; text-decoration: none; font-weight: bold; }
      
      .container .content.footer a:hover { text-decoration: underline; }
      
      
          </style>
      </head>ministry, desc, date, eventTime, location, rank, demo, need, solution, steps
      <body>
      <table class="body-wrap">
          <tr>
              <td class="container">
      
                  <!-- Message start -->
                  <table>
                      <tr>
                          <td align="center" class="masthead">
      
                              <h1>GC Communications Request</h1>
      
                          </td>
                      </tr>
                      <tr>
                          <td class="content">
      
                              <h2>Request Received</h2>
      
                              <ul>
                                <li><b>Ministry:</b> ${ministry}</li>
                                <li><b>Project Description:</b> ${desc}</li>
                                <li><b>Event Date:</b> ${date}</li>
                                <li><b>Event Time:</b> ${eventTime}</li>
                                <li><b>Location:</b> ${location}</li>
                                <li><b>Project Rank:</b> ${rank}</li>
                                <li><b>Target Demographic:</b> ${demo}</li>
                                <li><b>Need They Face:</b> ${need}</li>
                                <li><b>Solution You're Providing</b> ${solution}</li>
                                <li><b>Next Steps For Them:</b> ${steps}</li>
                              </ul>
      
                          </td>
                      </tr>
                  </table>
      
              </td>
          </tr>
          <tr>
              <td class="container">
      
                  
      
              </td>
          </tr>
      </table>
      </body>
      </html>`;
  return message;
}
