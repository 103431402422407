<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="https://green.thechapel.life"><img class="gc-logo"
        src="http://portal.thechapel.green/public/images/logo.png" alt="The Chapel Portal" /></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto pr-5">
        <li class="nav-item">
          <a *ngIf="isAuthenticated" class="nav-link" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!isAuthenticated" class="nav-link" href="https://api.thechapel.green/connect/microsoft">Login</a>
          <a *ngIf="isAuthenticated" class="nav-link" href="">Logout(Not
            working)</a>
        </li>
      </ul>
    </div>
  </nav>
</header>
