<div class="container">
  <h1 class="text-center pt-4">New Communications Request</h1>

  <form [formGroup]="newRequestForm" (ngSubmit)="onSubmit()">
    <div class="flex-container">
      <div class="flex-column">
        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Your Name</mat-label>
            <input formControlName="name" matInput type="text" />
          </mat-form-field>
        </div>


        <div class="flex-item">

          <mat-form-field appearance="outline" class="">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="Ex. pat@example.com" />
          </mat-form-field>
        </div>


        <div class="flex-item">

          <mat-form-field appearance="outline">
            <mat-label>Ministry You Represent</mat-label>
            <mat-select formControlName="ministry">
              <mat-option *ngFor="let ministry of ministries" [value]="ministry.ministry">
                {{ ministry.ministry }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>


        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Event / Project Name</mat-label>
            <input formControlName="projectName" matInput type="text" />
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Brief Project Description</mat-label>
            <textarea formControlName="desc" matInput type="text" rows="4"></textarea>
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Date of Event</mat-label>
            <input formControlName="eventDate" matInput #date [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </div>


        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Event Time (If Applicable)</mat-label>
            <input formControlName="eventTime" matInput type="text" />
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Location (If Applicable)</mat-label>
            <input formControlName="eventLocation" matInput type="text" />
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Project Ranking</mat-label>
            <mat-select formControlName="rank">
              <mat-option *ngFor="let rank of eventRanks" [value]="rank.rank">
                {{ rank.rank }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>All Materials Needed In-Hand</mat-label>
            <input formControlName="materialsInHandDate" [min]="minDate" matInput #date [matDatepicker]="picker2" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>



        <div class="flex-item">
          <p>Would you like to set up a meeting with our Media Team to discuss photography and/or videography
            options?</p>
          <mat-form-field appearance="outline">
            <mat-label>Select One</mat-label>
            <mat-select formControlName="meetingRequest">
              <mat-option *ngFor="let meeting of meetingRequest" [value]="meeting.meeting">
                {{ meeting.meeting }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

    </div>
    <div class="flex-container">
      <div class="flex-column">




        <div class="flex-item pt-5">
          <p class="m-0 pb-1">Please try to answer these questions with your specific request in
            mind rather than your
            ministry’s
            values and goals in general.</p>
        </div>

        <div class="flex-item side-by-side">
          <mat-form-field appearance="outline">
            <mat-label>Target Demographic</mat-label>
            <textarea formControlName="targetDemo" matInput type="text" rows="5"></textarea>
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Need They Face</mat-label>
            <textarea formControlName="needTheyFace" matInput type="text" rows="5"></textarea>
          </mat-form-field>
        </div>




        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Solution You're Providing</mat-label>
            <textarea formControlName="solution" matInput type="text" rows="5"></textarea>
          </mat-form-field>
        </div>



        <div class="flex-item">
          <mat-form-field appearance="outline">
            <mat-label>Next Steps For Them</mat-label>
            <textarea formControlName="nextSteps" matInput type="text" rows="5"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="flex-column side-bar pt-5">
        <div class="flex-item">
          <h2><i>Are you stuck or struggling when it comes to answering these questions?</i></h2>
          <p><i>Set up a consultation with us if you're unsure of how to proceed or if you'd
              like some extra help!</i></p>
        </div>
      </div>
    </div>
    <div class="submit-container pb-5">
      <button mat-raised-button color="primary" type="submit">Submit Request</button>
    </div>
  </form>
</div>
