import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnInit {
  token;
  constructor(
    private authService: AuthService,
    private localStorateService: LocalStorageService,
    private _Activatedroute: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit() {
    this._Activatedroute.queryParams.subscribe((params) => {
      console.log(params);
      const sendLogin = this.authService
        .login(params.access_token)
        .subscribe((res: any) => {
          // console.log(user);
          const uToken = res;
          console.log(res.user);
          this.localStorateService.set('token', res.jwt);
          this.localStorateService.set('at', params.access_token);
          this._router.navigate(['/dashboard']);
        });
      console.log(sendLogin);
    });
  }
}
