import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { TrelloService } from '../../../../services/trello.service';

@Component({
  selector: 'app-reprint',
  templateUrl: './reprint.component.html',
  styleUrls: ['./reprint.component.css'],
})
export class ReprintComponent implements OnInit {
  newRequestForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    projectName: new FormControl('', Validators.required),
    materialsInHandDate: new FormControl('', Validators.required),
    ministry: new FormControl('', Validators.required),
    qty: new FormControl('', Validators.required),
    desc: new FormControl('', Validators.required),
    ackCheck: new FormControl('', Validators.requiredTrue),
  });

  minDate: Date;

  ministries: any[] = [
    { ministry: 'Adults' },
    { ministry: 'Campus Administration' },
    { ministry: 'Care' },
    { ministry: 'Kids' },
    { ministry: 'Outreach' },
    { ministry: 'Pastoral Support' },
    { ministry: 'Special Needs' },
    { ministry: 'Serving' },
    { ministry: 'Students' },
    { ministry: 'Worship' },
  ];
  eventRanks: any[] = [
    { rank: 'All Church + Public' },
    { rank: 'All Church' },
    { rank: 'Ministry Specific (Large Scale)' },
    { rank: 'Ministry Specific (Small Scale)' },
    { rank: 'General Usage / Non-Event' },
  ];
  meetingRequest: any[] = [
    { meeting: 'Yes (Photo Requested)' },
    { meeting: 'Yes (Video Requested)' },
    { meeting: 'No' },
  ];

  formFieldRequired = new FormControl('', Validators.required);

  constructor(public trelloService: TrelloService) {
    const futureDay = new Date().getDate() + 20;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.minDate = new Date(currentYear, currentMonth, futureDay);
    console.log(this.minDate);
  }
  public classList;

  setClasses() {
    return this.classList;
  }

  removeClass() {
    this.classList = '';
  }

  onSubmit() {
    if (!this.newRequestForm.value.ackCheck) {
      this.classList = 'error';
      return this.classList;
    }
    // TODO: Use EventEmitter with form value
    const form = this.newRequestForm.value;
    if (this.newRequestForm.valid) {
      const options = {
        name: `${form.projectName}`,
        desc: `**Email:** ${form.email}
        **Submitted by:** ${form.name} - ${form.ministry}
        **Ministry:** ${form.ministry}
        **Project / Event Name:** ${form.projectName}
        **Project Description:** ${form.desc}
        **Quantity Needed** ${form.qty}`,
        pos: 'top',
        due: form.materialsInHandDate,
        idList: '5d42fe438bd87916fddb2a3f',
        idLabels: ``,
        keepFromSource: 'all',
        key: '66e7fec729b50f95ff81369940bdd2e1',
        token:
          '71894a2332faea92789e7f7a3344ba5a8cb38f865ac37d09ee41308f741e3a1a',
      };
      this.trelloService.postCard(options).subscribe((res) => {
        console.log(res);
      });
    }
  }

  updateDate() {
    console.log('date updated');
  }

  ngOnInit(): void {}
}
