<app-header [isAuthenticated]="isAuthenticated"></app-header>

<div class="container">
  <div class="row justify-content-center mt-5">
    <div class="list p-2" cdkDropList (cdkDropListDropped)="drop($event)">
      <h1>Main Signage</h1>
      <div class="draggable-box" *ngFor="let slide of signageSlides" cdkDrag>
        <div class="card mb-2" style="width: 36rem">
          <div class="row">
            <div class="col-md-5">
              <img class="card-img-top" src="https://api.thechapel.green{{ slide.mediaContent[0].url }}"
                alt="Card image cap" />
            </div>
            <div class="col-5">
              <h2>{{ slide.mediaName | titlecase }}</h2>
              <div>
                <mat-form-field appearance="fill">
                  <mat-label>{{ reformatDate(slide.expires) }}</mat-label>
                  <input matInput #date [matDatepicker]="picker" (dateChange)="updateDate(date, slide)" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker color="#7c7c7c"></mat-datepicker>
                </mat-form-field>
              </div>
              <!-- <div>
                <mat-form-field class="">
                  <mat-label>{{ slide.duration }} Seconds</mat-label>
                  <input
                    matInput
                    #duration
                    type="number"
                    name="duration"
                    placeholder="{{ slide.duration }}"
                    (ngModel)="(duration)"
                    (change)="updateDuration(duration, slide)"
                  />
                </mat-form-field>
              </div> -->
            </div>
            <div class="card-delete" (click)="deleteCard(this.slide)">
              <i class="trash-icon fas fa-trash-alt"></i>
            </div>
          </div>
        </div>
      </div>
      <app-file-upload (eventClicked)="refresh()" [slideCount]="slideCount"></app-file-upload>
    </div>
  </div>
</div>
