import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import {
  DashboardComponent,
  CommunicationDialog,
} from './components/dashboard/dashboard.component';
import { DashboardButtonComponent } from './components/dashboard-button/dashboard-button.component';
import { SignageAdminComponent } from './components/signage/signage-admin/signage-admin.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DragDropFileUploadDirective } from './drag-drop-file-upload.directive';
import { SignageDisplayComponent } from './components/signage/signage-display/signage-display.component';
import { SignageScreenComponent } from './components/signage/signage-screen/signage-screen.component';
import { AuthComponent } from './components/user/auth/auth.component';
import { LocalStorageService } from './services/local-storage.service';
import { FrontPageComponent } from './components/layouts/front-page/front-page.component';
import { TechHelpComponent } from './components/requests/tech-help/tech-help.component';
import { FacilitiesComponent } from './components/requests/facilities/facilities.component';
import { CommunicationsComponent } from './components/requests/communications/communications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewComponent } from './components/requests/communications/new/new.component';
import { ReprintComponent } from './components/requests/communications/reprint/reprint.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    CommunicationDialog,
    DashboardButtonComponent,
    SignageAdminComponent,
    FileUploadComponent,
    DragDropFileUploadDirective,
    SignageDisplayComponent,
    SignageScreenComponent,
    AuthComponent,
    FrontPageComponent,
    TechHelpComponent,
    FacilitiesComponent,
    CommunicationsComponent,
    NewComponent,
    ReprintComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [LocalStorageService],
  entryComponents: [DashboardComponent, CommunicationDialog],
  bootstrap: [AppComponent],
})
export class AppModule {}
