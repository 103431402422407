<div id="" class="carousel slide" data-ride="carousel" data-pause="false">
  <div class="carousel-inner">
    <ng-container *ngFor="let slide of signageSlides ; first as isFirst">
      <div class="carousel-item" data-interval="{{slide.duration}}" [ngClass]="{ 'active': isFirst }">
        <img class="d-block w-100" src="https://api.thechapel.green{{ slide.mediaContent[0].url }}
            " alt="slide">
      </div>
    </ng-container>
  </div>
</div>
