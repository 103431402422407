import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TrelloService } from '../../../../services/trello.service';
import { MailerService } from '../../../../services/mailer.service';
import { communicationsEmail } from '../../../../config/messages';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css'],
})
export class NewComponent implements OnInit {
  newRequestForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    projectName: new FormControl('', Validators.required),
    eventTime: new FormControl(''),
    eventLocation: new FormControl(''),
    eventDate: new FormControl('', Validators.required),
    desc: new FormControl('', Validators.required),
    targetDemo: new FormControl('', Validators.required),
    needTheyFace: new FormControl('', Validators.required),
    solution: new FormControl('', Validators.required),
    nextSteps: new FormControl('', Validators.required),
    materialsInHandDate: new FormControl('', Validators.required),
    ministry: new FormControl('', Validators.required),
    rank: new FormControl('', Validators.required),
    meetingRequest: new FormControl('', Validators.required),
  });

  minDate: Date;

  ministries: any[] = [
    { ministry: 'Adults' },
    { ministry: 'Campus Administration' },
    { ministry: 'Care' },
    { ministry: 'Kids' },
    { ministry: 'Outreach' },
    { ministry: 'Pastoral Support' },
    { ministry: 'Special Needs' },
    { ministry: 'Serving' },
    { ministry: 'Students' },
    { ministry: 'Worship' },
  ];
  eventRanks: any[] = [
    { rank: 'All Church + Public' },
    { rank: 'All Church' },
    { rank: 'Ministry Specific (Large Scale)' },
    { rank: 'Ministry Specific (Small Scale)' },
    { rank: 'General Usage / Non-Event' },
  ];
  meetingRequest: any[] = [
    { meeting: 'Yes (Photo Requested)' },
    { meeting: 'Yes (Video Requested)' },
    { meeting: 'No' },
  ];

  formFieldRequired = new FormControl('', Validators.required);

  constructor(
    public trelloService: TrelloService,
    public mailerService: MailerService
  ) {
    const futureDay = new Date().getDate() + 20;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.minDate = new Date(currentYear, currentMonth, futureDay);
    console.log(this.minDate);
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    const form = this.newRequestForm.value;
    if (this.newRequestForm.valid) {
      const options = {
        name: `${form.eventName}`,
        desc: `**Email:** ${form.email}
        **Submitted by:** ${form.name} - ${form.ministry}
        **Ministry:** ${form.ministry}
        **Project / Event Name:** ${form.projectName}
        **Project Description:** ${form.desc}
        **Event Date:** ${form.eventDate}
        **Event Time:** ${form.eventTime}
        **Media Team Meeting:** ${form.meeting}
        **Event Location:** ${form.eventLocation}
        **Project Rank:** ${form.rank}
        **Target Demographic:** ${form.targetDemo}
        **Need They Face:** ${form.needTheyFace}
        **Solution You're Providing:** ${form.solution}
        **Next Steps For Them:** ${form.nextSteps}`,
        pos: 'top',
        due: form.materialsInHandDate,
        idList: '5d42fe438bd87916fddb2a3f',
        idLabels: ``,
        keepFromSource: 'all',
        key: '66e7fec729b50f95ff81369940bdd2e1',
        token:
          '71894a2332faea92789e7f7a3344ba5a8cb38f865ac37d09ee41308f741e3a1a',
      };
      // this.trelloService.postCard(options).subscribe((res) => {
      //   console.log(res);
      // });
      var subject = 'Communications Request Form Submission';
      var message = communicationsEmail(
        form.ministry,
        form.desc,
        form.eventDate,
        form.eventTime,
        form.eventLocation,
        form.rank,
        form.targetDemo,
        form.needTheyFace,
        form.solution,
        form.nextSteps
      );
      var sendTo = form.email;
      var bccTo = '';
      var reqSubmitEmail = 'gc.communications@thechapel.life';
      var verse = 'Psalm3211';
      this.mailerService.sendMail(
        subject,
        message,
        reqSubmitEmail,
        sendTo,
        bccTo,
        verse
      );
    }
  }

  updateDate() {
    console.log('date updated');
  }

  ngOnInit(): void {}
}
