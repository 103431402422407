<div class="text-center fileUploadCard card mb-2" style="width: 36rem">
  <div class="card-body">
    <div class="card-text">
      <div class="fileUpload" appDragDropFileUpload (click)="fileField.click()" (fileDropped)="onUpload($event)">
        <i class="fileUploadIcon mt-4 fas fa-folder-plus"></i>
        <span class="">Add File</span>
        <input type="file" name="avatars" #fileField (change)="onUpload($event)" hidden multiple />
      </div>
    </div>
  </div>
</div>
