<app-header></app-header>

<div class='mt-5'>
  <div class="row justify-content-center">
    <div class="col">
      <div class="card ml-auto mr-auto" style="width: 50rem;">
        <div class="card-body">
          <h4 class="card-title">Welcome</h4>
          <p class="card-text">You have arrived at the The Chapel Green Campus staff web portal login page. 
            If
            you
            are looking for our main website, click below.</p>
          <div class="text-center">
            <a href="https://green.thechapel.life" class="btn btn-primary">Main Website</a>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
