import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-help',
  templateUrl: './tech-help.component.html',
  styleUrls: ['./tech-help.component.css']
})
export class TechHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
