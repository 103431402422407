<div *ngIf="
    button.urlType == 'requests' && button.Title != 'Communications' ||
    button.urlType == 'communications' ||
    button.urlType == 'admin'
    " class="dashboard-icon">
  <a routerLink="/{{ button.url }}" routerLinkActive="active">
    <div class="card transform text-center">
      <div class="card-body">
        <i class="{{ button.iconId }} card-icon"></i>
        <h6 class="card-subtitle mb-2 text-muted pt-2">{{ button.Title }}</h6>
      </div>
    </div>
  </a>
</div>
<div *ngIf="
    button.Title == 'Communications'" class="dashboard-icon">
  <a (click)="openDialog()">
    <div class="card transform text-center">
      <div class="card-body">
        <i class="{{ button.iconId }} card-icon"></i>
        <h6 class="card-subtitle mb-2 text-muted pt-2">{{ button.Title }}</h6>
      </div>
    </div>
  </a>
</div>
<div *ngIf="button.urlType == 'quickLink'" class="dashboard-icon">
  <a #externalLink class="" target="_blank" href="{{ button.url }}">
    <div class="card transform text-center">
      <div class="card-body">
        <i class="{{ button.iconId }} card-icon"></i>
        <h6 class="card-subtitle mb-2 text-muted pt-2">{{ button.Title }}</h6>
      </div>
    </div>
  </a>
</div>
