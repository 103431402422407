<div class="close-container">
  <div>
    <a mat-dialog-close><i class="fas fa-times"></i></a>
  </div>
</div>
<div>
  <h1 mat-dialog-title>Choose Request Type</h1>
</div>
<div mat-dialog-content>
  <div class="row m-5 text-center ">
    <div class="col">
      <a mat-dialog-close class="dialog-link" routerLink="/requests/communications/new" routerLinkActive="active">
        <i class="fas fa-file"></i>
        <h2 class="dialog-description">New</h2>
      </a>
    </div>
    <div class="col">
      <a mat-dialog-close class="dialog-link " routerLink="/requests/communications/reprint"
        routerLinkActive="active"><i class="fas fa-redo-alt"></i>
        <h2 class="dialog-description">Reprint</h2>

      </a>
    </div>
  </div>
</div>
