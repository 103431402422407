import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CommunicationsComponent } from './components/requests/communications/communications.component';
import { FacilitiesComponent } from './components/requests/facilities/facilities.component';
import { TechHelpComponent } from './components/requests/tech-help/tech-help.component';
import { SignageAdminComponent } from './components/signage/signage-admin/signage-admin.component';
import { SignageDisplayComponent } from './components/signage/signage-display/signage-display.component';
import { SignageScreenComponent } from './components/signage/signage-screen/signage-screen.component';
import { AuthComponent } from './components/user/auth/auth.component';
import { FrontPageComponent } from './components/layouts/front-page/front-page.component';
import { RouterGuardGuard } from './services/auth/router-guard.guard';
import { NewComponent } from './components/requests/communications/new/new.component';
import { ReprintComponent } from './components/requests/communications/reprint/reprint.component';

const routes: Routes = [
  { path: '', component: FrontPageComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RouterGuardGuard],
  },
  {
    path: 'requests',
    children: [
      {
        path: 'communications',
        component: CommunicationsComponent,

        canActivate: [RouterGuardGuard],
        children: [
          {
            path: 'new',
            component: NewComponent,
            canActivate: [RouterGuardGuard],
          },
          {
            path: 'reprint',
            component: ReprintComponent,
            canActivate: [RouterGuardGuard],
          },
        ],
      },
      { path: 'facilities', component: FacilitiesComponent },
      { path: 'tech-help', component: TechHelpComponent },
    ],
  },
  { path: 'signage-admin', component: SignageAdminComponent },
  {
    path: 'screen',
    component: SignageScreenComponent,
    children: [{ path: ':screen', component: SignageDisplayComponent }],
  },
  { path: 'connect/microsoft/redirect', component: AuthComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
