import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DashboardButtons } from '../models/DashboardButtons';
import { Observable } from 'rxjs';
import { SignageSlides } from '../models/SignageSlides';
import { token } from '../config/keys';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiUrl: string = 'https://api.thechapel.green';
  buttonsUrl: string = `${this.apiUrl}/dashboard-icons`;
  signageUrl: string = `${this.apiUrl}/screen-medias`;
  screenUrl: string = `${this.apiUrl}/screens`;
  uploadUrl: string = `${this.apiUrl}/upload`;
  params: string = `?token=${token}`;

  constructor(private http: HttpClient) {}
  getUser(jwt): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/Users/Me`, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
  }

  getButtons(jwt): Observable<DashboardButtons[]> {
    return this.http.get<DashboardButtons[]>(`${this.buttonsUrl}`, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
  }

  getSlides(name): Observable<SignageSlides[]> {
    return this.http.get<SignageSlides[]>(
      `${this.signageUrl}?_sort=order:ASC&screen.location_eq=${name}&`
    );
  }

  checkForNewSlides(name): Observable<SignageSlides[]> {
    return this.http.get<SignageSlides[]>(
      `${this.signageUrl}?screen.location_eq=${name}`
    );
  }

  changeScreenUpdate(id, body): Observable<any> {
    return this.http.put(`${this.screenUrl}/${id}?token=${token}`, body);
  }

  updateSlideOrder(id, body, jwt): Observable<any> {
    return this.http.put<SignageSlides[]>(`${this.signageUrl}/${id}`, body, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
  }

  updateSlide(id, body, jwt): Observable<any> {
    return this.http.put<SignageSlides[]>(`${this.signageUrl}/${id}`, body, {
      headers: { Authorization: `Bearer ${jwt}` },
    });
  }

  uploadSlide(body, options): Observable<any> {
    return this.http.post<any>(`$(this.uploadUrl)/`, body, options);
  }

  deleteSlide(res: any): Observable<any> {
    return this.http.delete<any>(
      `${this.apiUrl}/screen-medias/${res.id}${this.params}`
    );
  }

  deleteImg(res: any): Observable<any> {
    return this.http.delete<any>(
      `${this.apiUrl}/upload/files/${res.mediaContent[0].id}${this.params}`
    );
  }
}
