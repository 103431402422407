import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { DashboardButtons } from '../../models/DashboardButtons';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  dashboardButtons: DashboardButtons[];
  user: any;
  isAuthenticated: boolean = false;
  isCommunications: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    public dialog: MatDialog
  ) {}

  openDialog() {
    const dialogRef = this.dialog.open(CommunicationDialog);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    this.dashboardService
      .getButtons(localStorage.token)
      .subscribe((dashboardButtons) => {
        this.dashboardButtons = dashboardButtons;
        this.dashboardButtons = dashboardButtons.sort(
          (a: any, b: any) => a.iconOrder - b.iconOrder
        );
      });
    this.dashboardService.getUser(localStorage.token).subscribe((User) => {
      this.user = User;
      console.log(this.user.role.name);

      if (
        this.user.role.name == 'Admin' ||
        this.user.role.name == 'Authenticated' ||
        this.user.role.name == 'Communications'
      ) {
        this.isAuthenticated = true;
        console.log('user is Auth');
      }
      if (
        this.user.role.name == 'Admin' ||
        this.user.role.name == 'Communications'
      ) {
        this.isCommunications = true;
        console.log('user is communications');
      }

      if (this.user.role.name == 'Admin') {
        this.isAdmin = true;
        console.log('user is admin');
      }
    });
  }
}
@Component({
  selector: 'communication-dialog',
  templateUrl: 'communications-dialog.html',
  styleUrls: ['./dashboard.component.css'],
})
export class CommunicationDialog {}
