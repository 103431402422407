import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { token } from '../../config/keys';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  @Input() slideCount: number;
  @Output() eventClicked = new EventEmitter<Event>();

  selectedFile: File = null;
  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }
  constructor(
    private http: HttpClient,
    public dashboardService: DashboardService
  ) {}

  onUpload(event) {
    console.log(event[0]);
    if (event.target === undefined) {
      this.selectedFile = <File>event[0];
    } else {
      this.selectedFile = <File>event.target.files[0];
    }

    const fd = new FormData();
    fd.append('files', this.selectedFile);

    const params: string = `?token=${token}`;

    this.http
      .post(`https://api.thechapel.green/upload${params}`, fd)
      .subscribe((res) => {
        res[0].name = res[0].name.replaceAll('.jpg', '');
        res[0].name = res[0].name.replaceAll('_', ' ');
        res[0].name = res[0].name.toLowerCase().replaceAll(' escreen', '');
        const newScreenMedia = {
          mediaName: `${res[0].name}`,
          mediaContent: `${res[0].id}`,
          order: `${this.slideCount + 1}`,
          screen: { id: '1' },
        };
        this.http
          .post(
            `https://api.thechapel.green/screen-medias${params}`,
            newScreenMedia
          )
          .subscribe((res) => {
            const screenName = 'main';
            this.dashboardService
              .checkForNewSlides(screenName)
              .subscribe((refresh) => {
                const update = refresh[0].screen.Updated;
                if (!update) {
                  const body = { Updated: true };
                  const x = this.dashboardService
                    .changeScreenUpdate(refresh[0].screen.id, body)
                    .subscribe(() => {
                      this.ngOnInit();
                    });
                }
              });
            this.eventClicked.emit(event);
            this.ngOnInit();
          });
      });
  }

  ngOnInit(): void {}
}
