import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../../services/dashboard.service';
import { SignageSlides } from '../../../models/SignageSlides';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-signage-admin',
  templateUrl: './signage-admin.component.html',
  styleUrls: ['./signage-admin.component.css'],
})
export class SignageAdminComponent implements OnInit {
  signageSlides: SignageSlides[];
  slideCount: number;
  user: any;
  isAuthenticated: boolean;
  isCommunications: boolean;
  isAdmin: boolean;
  constructor(public dashboardService: DashboardService) {}

  drop(event: CdkDragDrop<string[]>) {
    const slide_media: any = this.signageSlides;
    let x: any;
    // console.log(this.signageSlides[event.currentIndex].mediaContent[0].url);

    moveItemInArray(slide_media, event.previousIndex, event.currentIndex);
    for (let i = 0; i < slide_media.length; i++) {
      let order = i.toString();
      let body = { order: order };
      this.dashboardService
        .updateSlideOrder(slide_media[i].id, body, localStorage.token)
        .subscribe((res) => {
          console.log(res);
        });
    }
  }

  refresh() {
    this.ngOnInit();
  }

  public deleteCard(slide) {
    const screenName = 'main';
    this.dashboardService.checkForNewSlides(screenName).subscribe((refresh) => {
      const update = refresh[0].screen.Updated;
      if (!update) {
        const body = { Updated: true };
        const x = this.dashboardService
          .changeScreenUpdate(refresh[0].screen.id, body)
          .subscribe(() => {
            this.ngOnInit();
          });
      }
    });
    this.dashboardService.deleteSlide(slide).subscribe(() => {
      this.dashboardService.deleteImg(slide).subscribe(() => {
        this.refresh();
      });
    });
  }

  updateDuration(duration, slide) {
    const body = { duration: duration.value };
    this.dashboardService
      .updateSlide(slide.id, body, localStorage.token)
      .subscribe((res) => {
        const body = { Updated: true };
        const x = this.dashboardService
          .changeScreenUpdate(slide.screen.id, body)
          .subscribe((res) => {});
      });
  }

  reformatDate(dt) {
    // console.log(dt);
    if (dt) {
      var newDt = moment(dt, 'YYYY-MM-DD').format('MM/DD/YYYY');
      // console.log(newDt);
      return `Expires ${newDt}`;
    } else {
      return 'Set Expiration';
    }
  }

  updateDate(dt, slide) {
    console.log(dt);
    var newDt = moment(dt.value, 'MM/DD/YYYY').format('YYYY-MM-DD');
    console.log(newDt);
    const body = { expires: newDt };
    this.dashboardService
      .updateSlide(slide.id, body, localStorage.token)
      .subscribe((res) => {
        const body = { Updated: true };
        const x = this.dashboardService
          .changeScreenUpdate(slide.screen.id, body)
          .subscribe((res) => {
            this.refresh();
          });
      });
  }

  ngOnInit(): void {
    this.dashboardService.getSlides('main').subscribe((signageSlides) => {
      this.signageSlides = signageSlides.sort(
        (a: any, b: any) => a.order - b.order
      );
      this.slideCount = signageSlides.length;
    });

    this.dashboardService.getUser(localStorage.token).subscribe((User) => {
      this.user = User;

      if (
        this.user.role.name == 'Admin' ||
        'Authenticated' ||
        'Communications'
      ) {
        this.isAuthenticated = true;
      }
      if (this.user.role.name == 'Admin' || 'Communications') {
        this.isCommunications = true;
      }

      if (this.user.role.name == 'Admin') {
        this.isAdmin = true;
      }
    });
  }
}
