import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TrelloService {
  constructor(private http: HttpClient) {}

  postCard(options): Observable<any> {
    return this.http.post<any>(`https://api.trello.com/1/cards`, options);
  }
}
